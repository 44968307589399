/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  // Google Analytics setup
  // const googleAnalyticsScript = document.createElement('script');
  // googleAnalyticsScript.setAttribute('async', '');
  // googleAnalyticsScript.src = 'https://www.google-analytics.com/analytics.js';
  // document.body.appendChild(googleAnalyticsScript);
  //
  // window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
  // ga('create', '');
  // ga('set', 'page', location.pathname);
  // ga('send', 'pageview');
};

exports.onRouteUpdate = () => {
  // Segment page tracking - window.analytics is available due to gatsby-plugin-segment-js being setup in gatsby-config.js
  // window.analytics && window.analytics.page();

  // Google Analytics page tracking
  // ga('set', 'page', location.pathname);
  // ga('send', 'pageview');
};
